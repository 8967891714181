import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';

import { authEndpoint } from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

const appStoreKeyIV = [env_constants.APP_STORE_KEY, env_constants.APP_STORE_IV];
const CONTENT_TYPE = { 'Content-type': 'application/json' };

export const fetchClientData: any = createAsyncThunk(
  'auth/clientData',
  async () => {
    let result;

    await authEndpoint
      .get(`${env_constants.APP_STORE_API}/Client/ip`, {
        headers: {
          ...CONTENT_TYPE,
          clientid: encryptData(`${window.navigator.userAgent}`, appStoreKeyIV),
        },
      })
      .then((response) => {
        const decrypted = decryptData(response.data, appStoreKeyIV);
        result = decrypted.ip;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data, appStoreKeyIV);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const fetchValues: any = createAsyncThunk(
  'auth/values',
  async (apiParams: { data: any; token: string }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = {
      ...{
        headers: {
          ...CONTENT_TYPE,
          Authorization: `Bearer ${apiParams.token}`,
          clientid: clientId,
        },
      },
    };

    await authEndpoint
      .get(`${env_constants.PA_API_BASE_URL}/Values`, options)
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = {
          folderSearchSortTypes: decrypted.folderSearchSortTypes,
          googleDriveRootFolderName: decrypted.googleDriveRootFolderName,
        };
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const refreshToken: any = createAsyncThunk(
  'auth/refreshToken',
  async (apiParams: { data: any; token: string }, thunkAPI: any) => {
    let result;
    let encryptedData = null;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
      appStoreKeyIV,
    );

    encryptedData = encryptData(apiParams.data, appStoreKeyIV);

    let headers: any = {
      ...CONTENT_TYPE,
    };

    headers = {
      ...headers,
      clientid: clientId,
    };

    await authEndpoint
      .patch(
        `${env_constants.APP_STORE_API}/Auth/refresh-token`,
        encryptedData,
        {
          headers,
        },
      )
      .then((response) => {
        const decrypted = decryptData(response.data, appStoreKeyIV);

        result = {
          userId: decrypted.userId,
          firstName: decrypted.userFirstName,
          lastName: decrypted.userLastName,
          picture: decrypted.userPicture,
          roleId: decrypted.userRoleId,
          sessionId: decrypted.sessionId,
          token: decrypted.appToken?.token,
          expiry: decrypted.appToken?.expiry,
        };
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data, appStoreKeyIV);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const logout: any = createAsyncThunk(
  'auth/logout',
  async (apiParams: { data: any; token: string }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
      appStoreKeyIV,
    );

    const encryptedData = encryptData(apiParams.data, appStoreKeyIV);

    const options = {
      headers: {
        Authorization: `Bearer ${apiParams.token}`,
        ...CONTENT_TYPE,
        clientid: clientId,
      },
    };

    await authEndpoint
      .delete(`${env_constants.APP_STORE_API}/Auth/logout`, {
        ...options,
        data: encryptedData,
      })
      .then((response) => {
        const decrypted = decryptData(response.data, appStoreKeyIV);

        return decrypted;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data, appStoreKeyIV);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);
