import {
  ArrowUpOutlined,
  EllipsisOutlined,
  FolderFilled,
  HomeFilled,
  InfoCircleOutlined,
} from '@ant-design/icons';
import {
  Button,
  Checkbox,
  CheckboxProps,
  Dropdown,
  Input,
  MenuProps,
  Radio,
  RadioChangeEvent,
  Table,
  Tooltip,
} from 'antd';
import { useFormikContext } from 'formik';
import moment from 'moment';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import env_constants from '../../../internals/env/env_constants.json';
import {
  selectFolderSearchSortTypes,
  selectGoogleDriveRootFolderName,
  selectUser,
} from '../../authentication/redux/selectors';
import FolderBreadCrumbsTrail from '../folder_breadcrumbs_trail/FolderBreadCrumbsTrail';
import { fetchFolders } from '../redux/async_thunks';
import {
  getClientFoldersCurrentTrailIndex,
  selectClientFolders,
} from '../redux/selectors';
import { returnToFolderLevel, setDestinationFolder } from '../redux/slice';

type FolderProps = {
  folderId?: string;
  folderName?: string;
  trailIndex?: number;
  searchValue?: string;
  sortType?: string;
  orderAsc?: boolean;
  nextPageToken?: string | null;
};

type FolderSelectProps = {
  closeHandler: () => void;
};

const FolderSelect = ({ closeHandler }: FolderSelectProps) => {
  let clickTimeout: any;

  const { setFieldValue } = useFormikContext();

  const DATE_FORMAT = 'MMMM Do YYYY, h:mm:ss a';
  const {
    CLIENT_FOLDER_SEARCH_KEYWORD_MIN_LENGTH: KEYWORD_LENGTH,
    GOOGLE_DRIVE_BASE_URL,
  } = env_constants;

  const scrollableRef: any = useRef(null);

  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const googleDriveRootFolderName = useSelector(
    selectGoogleDriveRootFolderName,
  );

  const clientFolders = useSelector(selectClientFolders);
  const currentTrailIndex = useSelector(getClientFoldersCurrentTrailIndex);

  const nextTrailIndex = currentTrailIndex + 1;
  const previousTrailIndex = currentTrailIndex - 1;

  const defaultOptions = {
    searchValue: undefined,
    orderAsc: true,
    sortType: 'name',
  };

  const sortTypes = useSelector(selectFolderSearchSortTypes)?.map(
    (sortType) => {
      return {
        label: sortType.key,
        value: sortType.value,
      };
    },
  );

  const [sortType, setSortType] = useState<string>(defaultOptions.sortType);
  const [orderAsc, setOrderAsc] = useState<boolean | undefined>(
    defaultOptions.orderAsc,
  );
  const [searchValue, setSearchValue] = useState<string | undefined>(
    defaultOptions.searchValue,
  );

  const [selectedRowKey, setSelectedRowKey] = useState<any>(null);
  const [selectedRowDetails, setSelectedRowDetails] = useState<any>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isBatchLoading, setIsBatchLoading] = useState(false);

  const [highlightRowFolderId, setHighlightRowFolderId] = useState<
    string | undefined
  >();

  const columns = [
    {
      title: 'Folder Name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        return (
          <div className="l-folder-select__folder-name">
            <FolderFilled /> {name}
          </div>
        );
      },
    },
    {
      title: 'Owner',
      dataIndex: 'owners',
      key: 'owners',
      render: (owners: { displayName: string; emailAddress: string }[]) => {
        return <>{owners[0].displayName}</>;
      },
    },
    {
      title: 'Date Created',
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (createdTime: string) => {
        const formattedCreatedTime = moment(createdTime).format(DATE_FORMAT);

        return <>{formattedCreatedTime}</>;
      },
    },
    {
      title: 'Last Modified',
      dataIndex: 'modifiedTime',
      key: 'modifiedTime',
      render: (modifiedTime: string) => {
        const formattedModifiedTime = moment(modifiedTime).format(DATE_FORMAT);

        return <>{formattedModifiedTime}</>;
      },
    },
    {
      title: '',
      dataIndex: '',
      key: '',
      render: (field: any, data: any) => {
        const items: MenuProps['items'] = [
          {
            label: (
              <a
                href="#"
                className="h-text-decoration--none"
                onClick={(e) => {
                  e.preventDefault();

                  getFolders({
                    folderId: data.id,
                    folderName: data.name,
                    trailIndex: nextTrailIndex,
                    ...defaultOptions,
                  });
                }}
              >
                View
              </a>
            ),
            key: '0',
          },
          {
            label: (
              <a
                href={`${GOOGLE_DRIVE_BASE_URL}/${data.id}`}
                className="h-text-decoration--none"
                target="_blank"
                rel="noreferrer"
              >
                Open in new tab
              </a>
            ),
            key: '1',
          },
        ];

        return (
          <Dropdown menu={{ items }} trigger={['click']}>
            <button
              className="l-folder-select__options-btn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <EllipsisOutlined rotate={90} />
            </button>
          </Dropdown>
        );
      },
    },
  ];

  const scrollToTop = () => {
    if (scrollableRef?.current) {
      scrollableRef.current.scrollTo({
        top: 0,
        behavior: 'auto',
      });
    }
  };

  const getSearchOptions = (newOptions: FolderProps) => {
    const currentFolder = clientFolders?.[currentTrailIndex];

    // Folder options stored in application state
    const currenOptions = {
      folderId: currentFolder?.folderId,
      folderName: currentFolder?.folderName,
      trailIndex: currentTrailIndex,
      searchValue: searchValue,
      sortType: sortType,
      orderAsc: orderAsc,
      nextPageToken: currentFolder?.nextPageToken,
    };

    return {
      ...currenOptions,
      ...newOptions,
    };
  };

  const getFolders = async (params: FolderProps, isLoadNextBatch?: boolean) => {
    if (user) {
      if (isLoadNextBatch) {
        setIsBatchLoading(true);
      } else {
        setIsLoading(true);
      }

      const options = getSearchOptions(params);

      const filteredOptions = Object.entries(options).filter(
        ([key, value]) =>
          value !== undefined && key !== 'trailIndex' && key !== 'folderName',
      );

      const data = {
        userId: user.userId,
        sessionId: user.sessionId,
        ...Object.fromEntries(filteredOptions),
      };

      await dispatch(
        fetchFolders({
          token: user.token,
          data: data,
          trailIndex: options.trailIndex,
          folderName: options.folderName ?? googleDriveRootFolderName,
          isLoadNextBatch: isLoadNextBatch,
        }),
      );

      if (!isLoadNextBatch) {
        setSelectedRowKey(null);
        setSelectedRowDetails(null);
      }

      if (isLoadNextBatch) {
        setIsBatchLoading(false);
      } else {
        setIsLoading(false);
        scrollToTop();
      }
    }
  };

  const handleHomeClick = () => {
    const highlightFolder = clientFolders?.[1];

    setHighlightRowFolderId(highlightFolder?.folderId);
    dispatch(returnToFolderLevel(0));
  };

  const handleBackClick = () => {
    const highlightFolder = clientFolders?.[currentTrailIndex];

    setHighlightRowFolderId(highlightFolder?.folderId);
    dispatch(returnToFolderLevel(previousTrailIndex));
  };

  const handleTrailClick = ({ index }: { index: number }) => {
    const highlightFolder = clientFolders?.[index + 1];

    setHighlightRowFolderId(highlightFolder?.folderId);
    dispatch(returnToFolderLevel(index));
  };

  const handleSearchClick = () => {
    if (searchValue && searchValue.length >= KEYWORD_LENGTH) {
      getFolders({
        searchValue: searchValue,
      });
    }
  };

  const handleClearSearchClick = () => {
    setSearchValue(undefined);

    getFolders({
      searchValue: undefined,
    });
  };

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const handleSortTypeRadioChange = ({
    target: { value },
  }: RadioChangeEvent) => {
    setSortType(value);

    getFolders({
      sortType: value,
    });
  };

  const handleOrderAscCheckboxChange: CheckboxProps['onChange'] = (e) => {
    setOrderAsc(e.target.checked);

    getFolders({
      orderAsc: e.target.checked,
    });
  };

  const handleResetClick = () => {
    getFolders({ ...defaultOptions });
  };

  const handleRowDoubleClick = (record: any) => {
    getFolders({
      folderId: record.id,
      folderName: record.name,
      trailIndex: nextTrailIndex,
      ...defaultOptions,
    });
  };

  const handleRowClick = (record) => {
    setSelectedRowKey(record.id);
    setSelectedRowDetails(record);
  };

  const handleSelectFolderClick = () => {
    dispatch(setDestinationFolder(selectedRowDetails));
    setFieldValue('clientsFolderId', selectedRowDetails.name);
    closeHandler();
  };

  const handleBodyScroll = () => {
    if (isLoading && isBatchLoading) {
      return false;
    }

    const threshold = 1;
    const scrollableElement: any = scrollableRef?.current;

    if (
      scrollableElement &&
      scrollableElement.scrollTop + scrollableElement.clientHeight >=
        scrollableElement.scrollHeight - threshold
    ) {
      if (
        !isLoading &&
        !isBatchLoading &&
        clientFolders?.[currentTrailIndex]?.nextPageToken
      ) {
        // Load the next batch of folders
        getFolders({}, true);
      }
    }
  };

  const renderBackTopBtn = () => {
    if (currentTrailIndex < 1) {
      return <></>;
    }

    return (
      <Button
        style={{ marginBottom: '6px' }}
        type="dashed"
        onClick={handleBackClick}
      >
        Back Top
      </Button>
    );
  };

  useEffect(() => {
    const scrollableElement: any = scrollableRef.current;

    if (scrollableElement) {
      scrollableElement.addEventListener('scroll', handleBodyScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', handleBodyScroll);
      }
    };
  }, [clientFolders, currentTrailIndex, isLoading]);

  useEffect(() => {
    const currentFolder = clientFolders?.[currentTrailIndex];

    setSearchValue(currentFolder?.searchValue);
    setSortType(currentFolder?.sortType || sortType);
    setOrderAsc(
      currentFolder?.orderAsc === undefined ? true : currentFolder?.orderAsc,
    );
  }, [clientFolders, currentTrailIndex]);

  // Scroll the table to put the last picked folder row into view
  useEffect(() => {
    if (highlightRowFolderId && scrollableRef?.current) {
      const rowElement = scrollableRef.current.querySelector(
        `tr[data-row-key="${highlightRowFolderId}"]`,
      );

      if (rowElement) {
        const rowTop = rowElement.offsetTop;
        const rowHeight = rowElement.clientHeight;
        const visibleHeight = scrollableRef.current.clientHeight;

        if (
          rowTop < scrollableRef.current.scrollTop ||
          rowTop + rowHeight > scrollableRef.current.scrollTop + visibleHeight
        ) {
          scrollableRef.current.scrollTo({
            top: rowTop,
            behavior: 'auto',
          });
        }

        setHighlightRowFolderId(undefined);
      }
    }
  }, [currentTrailIndex]);

  useEffect(() => {
    if (!clientFolders?.length) {
      getFolders({ trailIndex: nextTrailIndex });
    }
  }, []);

  return (
    <>
      {isLoading && <FullPageLoader />}
      <div className="l-folder-select">
        <div className="l-folder-select__search">
          <Input
            value={searchValue}
            onChange={handleSearchInputChange}
            placeholder="Search folder by name..."
            onPressEnter={handleSearchClick}
          />
          <Button type="primary" onClick={handleSearchClick}>
            Search
          </Button>
          <Button onClick={handleClearSearchClick}>Clear</Button>
          <Button
            onClick={handleResetClick}
            className="l-folder-select__reset-btn"
          >
            Reset / Refresh
          </Button>
        </div>
        <div className="l-folder-select__sort">
          <Radio.Group
            options={sortTypes}
            onChange={handleSortTypeRadioChange}
            value={sortType}
            optionType="button"
          />
          <div>
            <Checkbox
              checked={orderAsc}
              onChange={handleOrderAscCheckboxChange}
            >
              Ascending Order
            </Checkbox>
          </div>
          <Tooltip
            placement="top"
            title={
              <div>
                <p style={{ margin: '0 0 8px' }}>
                  Search and sorting are only applied within the current folder.
                </p>
                <p style={{ margin: 0 }}>
                  Double click a row to open the folder.
                </p>
              </div>
            }
          >
            <span style={{ marginLeft: 'auto' }}>
              <InfoCircleOutlined style={{ opacity: 0.5, fontSize: '20px' }} />
            </span>
          </Tooltip>
        </div>

        <div className="l-folder-select__bread-crumbs">
          <Button
            disabled={clientFolders?.length < 2}
            onClick={handleHomeClick}
            style={{ marginRight: '8px' }}
          >
            <HomeFilled style={{ opacity: 0.7 }} />
          </Button>
          <Button
            disabled={clientFolders?.length < 2}
            onClick={handleBackClick}
          >
            <ArrowUpOutlined />
          </Button>
          <FolderBreadCrumbsTrail handleClick={handleTrailClick} />
        </div>
        <div ref={scrollableRef} className="l-folder-select__table-container">
          {clientFolders?.[currentTrailIndex]?.folders && (
            <Table
              dataSource={clientFolders?.[currentTrailIndex]?.folders}
              pagination={false}
              columns={columns}
              onRow={(record) => ({
                onDoubleClick: () => handleRowDoubleClick(record),
                onClick: () => {
                  if (clickTimeout) {
                    clearTimeout(clickTimeout);
                    clickTimeout = null;
                    return;
                  }

                  clickTimeout = setTimeout(() => {
                    handleRowClick(record);
                    clearTimeout(clickTimeout);
                    clickTimeout = null;
                  }, 100);
                },
                style: {
                  background: record.id === selectedRowKey ? '#f9e9c8' : '',
                },
              })}
              locale={{
                emptyText: (
                  <>
                    <p
                      style={{
                        margin: '4px 0 8px',
                        fontSize: '15px',
                        color: '#999',
                      }}
                    >
                      No folders in here
                    </p>
                    {renderBackTopBtn()}
                  </>
                ),
              }}
              rowKey="id"
            />
          )}

          {clientFolders?.[currentTrailIndex]?.nextPageToken && (
            <div className="l-folder-select__batch-loading">
              {isBatchLoading && <FullPageLoader />}
            </div>
          )}
        </div>
        <div className="l-folder-select__submit">
          {selectedRowDetails && !isLoading && (
            <p>
              <FolderFilled />
              <strong>{selectedRowDetails?.name}</strong> - Save APEX report to
              this folder?
            </p>
          )}
          <Button
            disabled={!selectedRowDetails || isLoading}
            type="primary"
            onClick={() => {
              handleSelectFolderClick();
            }}
          >
            Yes, Select Folder
          </Button>
        </div>
      </div>
    </>
  );
};

export default FolderSelect;
