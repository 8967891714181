import { notification } from 'antd';
import axios from 'axios';

import { decryptData } from '../utils/api';
import { clearSessionStorage } from '../utils/common';

const isValidHTML = (htmlString: string) => {
  const tempElement = document.createElement('div');

  tempElement.innerHTML = htmlString.trim();

  return tempElement.children.length > 0;
};

const commonErrorHandler = () => {
  clearSessionStorage();
  window.location.href = '/error';
};

/*
 * Axios instance for common endpoints
 **/
export const endpoint = axios.create({});

endpoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.data && error?.response?.status !== 404) {
      if (!isValidHTML(error.response.data)) {
        const decryptedError = decryptData(error.response.data);

        if (decryptedError?.resMessage) {
          notification.error({
            message: 'Request Failed',
            description: decryptedError.resMessage,
          });
        }
      }
    }

    if (error?.response?.status === 401) {
      commonErrorHandler();
    }

    return Promise.reject(error);
  },
);

/*
 * Axios instance for authentication endpoints
 **/
export const authEndpoint = axios.create({});

authEndpoint.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      commonErrorHandler();
    } catch (e) {
      window.location.href = '/error';
    }

    return Promise.reject(error);
  },
);
