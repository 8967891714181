import { useEffect, useState } from 'react';

import PropertySearch from '../../features/property_search/PropertySearch';

export type RouteType = {
  path: string;
  name: string;
  component?: () => JSX.Element;
  exact?: boolean;
  strict?: boolean;
  completePath: string;
};

export const useRoutes = () => {
  const [routes, setRoutes] = useState<RouteType[]>([]);

  useEffect(() => {
    const baseRoutes: RouteType[] = [
      {
        path: '/',
        name: 'Property Search',
        component: PropertySearch,
        exact: true,
        strict: true,
        completePath: '/',
      },
    ];

    setRoutes(baseRoutes);
  }, []);

  return routes;
};
