import { CloseCircleFilled, FolderOutlined } from '@ant-design/icons';
import { Button, Input, InputNumber, notification } from 'antd';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectUser } from '../../authentication/redux/selectors';
import FolderSelectModal from '../folder_select/folder_select_modal/FolderSelectModal';
import { generateApexReport } from '../redux/async_thunks';
import {
  selectDestinationFolder,
  selectProperty,
  selectPropertyId,
} from '../redux/selectors';
import { setDestinationFolder, setPropertyApexReportUrl } from '../redux/slice';
import PreviewModal from './preview_modal/PreviewModal';

const validationSchema = Yup.object().shape({
  cashAmount: Yup.number()
    .required('Cash is required')
    .positive('Cash must be a positive number')
    .typeError('Invalid Cash amount'),
  equityAmount: Yup.number()
    .required('Equity is required')
    .positive('Equity must be a positive number')
    .typeError('Invalid Equity amount'),
  equityInterestRate: Yup.number()
    .required('I/R Equity Release is required')
    .positive('I/R Equity Release must be a positive number')
    .typeError('Invalid IR Equity Release percentage'),
  clientsFolderId: Yup.string().required('Please select a folder'),
});

const ApexReportForm = () => {
  const dispatch = useDispatch();

  const formikRef: any = useRef();

  const user = useSelector(selectUser);
  const propertyId = useSelector(selectPropertyId);
  const property = useSelector(selectProperty);
  const destinationFolder = useSelector(selectDestinationFolder);

  const [isFolderSelectModalActive, setIsFolderSelectModalActive] =
    useState(false);

  const [isPreviewModalActive, setIsPreviewModalActive] = useState(false);

  const convertPercentageStringToNumber = (percentageString: string) => {
    const cleanedString = percentageString.replace('%', '').trim();
    const floatValue = parseFloat(cleanedString);

    return floatValue;
  };

  const convertDollarStringToNumber = (dollarString: string) => {
    const cleanedString = dollarString.replace(/[^0-9.]/g, '');
    const floatValue = parseFloat(cleanedString);

    return floatValue;
  };

  const [isFormLoading, setIsFormLoading] = useState(false);

  const getApexReport = async (params: any) => {
    if (user && propertyId && destinationFolder?.id) {
      setIsFormLoading(true);

      const { token, userId, sessionId } = user;

      const response = await dispatch(
        generateApexReport({
          token: token,
          data: {
            userId: userId,
            sessionId: sessionId,
            propertyId: propertyId,
            ...params,
            // Convert to decimal
            ...{ equityInterestRate: params.equityInterestRate / 100 },
            ...{ clientsFolderId: destinationFolder.id },
          },
        }),
      ).unwrap();

      dispatch(setPropertyApexReportUrl(response.apexReportUrl));
      setIsPreviewModalActive(true);

      notification.success({
        message: 'Success!',
        description: 'APEX Report Generated',
      });

      setIsFormLoading(false);
    }
  };

  const handleFolderSelectModalClose = () => {
    setIsFolderSelectModalActive(false);
  };

  const handlePreviewModalClose = () => {
    setIsPreviewModalActive(false);
  };

  const handlePreviewClick = () => {
    setIsPreviewModalActive(true);
  };

  useEffect(() => {
    if (property && formikRef?.current) {
      formikRef.current.resetForm();
    }
  }, [property]);

  useEffect(() => {
    if (destinationFolder?.name) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        clientsFolderId: destinationFolder.name,
      });
    }
  }, [destinationFolder?.name]);

  return (
    <div className="l-property-search__form">
      {isFormLoading && <FullPageLoader />}
      {isPreviewModalActive && (
        <PreviewModal closeHandler={handlePreviewModalClose} />
      )}
      <Formik
        innerRef={formikRef}
        initialValues={{
          cashAmount: property?.clientDetails?.cashAmount
            ? convertDollarStringToNumber(property?.clientDetails?.cashAmount)
            : undefined,
          equityAmount: property?.clientDetails?.equityAmount
            ? convertDollarStringToNumber(property?.clientDetails?.equityAmount)
            : undefined,
          equityInterestRate: property?.clientDetails?.equityInterestRate
            ? convertPercentageStringToNumber(
                property?.clientDetails?.equityInterestRate,
              )
            : undefined,
          clientsFolderId: destinationFolder?.name ?? undefined,
        }}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={(values) => {
          getApexReport(values);
        }}
      >
        {({ handleSubmit }) => {
          return (
            <>
              {isFolderSelectModalActive && (
                <FolderSelectModal
                  closeHandler={handleFolderSelectModalClose}
                />
              )}
              <Form onSubmit={handleSubmit}>
                <div className="l-property-search__field">
                  <label htmlFor="cashAmount">Cash</label>
                  <div>
                    <Field
                      name="cashAmount"
                      render={({ field, form }) => (
                        <InputNumber
                          {...field}
                          placeholder="100000"
                          min={0}
                          step={0.01}
                          precision={2}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          prefix="$"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="cashAmount"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="l-property-search__field">
                  <label htmlFor="equityAmount">Equity</label>
                  <div>
                    <Field
                      name="equityAmount"
                      render={({ field, form }) => (
                        <InputNumber
                          {...field}
                          placeholder="100000"
                          min={0}
                          step={0.01}
                          precision={2}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          prefix="$"
                        />
                      )}
                    />
                    <ErrorMessage
                      name="equityAmount"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="l-property-search__field">
                  <label htmlFor="equityInterestRate">
                    Equity Interest Rate
                  </label>
                  <div>
                    <Field name="equityInterestRate" type="number" step={0.01}>
                      {({ field, form }) => (
                        <InputNumber
                          {...field}
                          placeholder="25"
                          min={1}
                          onChange={(value) =>
                            form.setFieldValue(field.name, value)
                          }
                          suffix="%"
                        />
                      )}
                    </Field>
                    <ErrorMessage
                      name="equityInterestRate"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div className="l-property-search__field l-property-search__field--bordered l-property-search__field--pointer">
                  <label htmlFor="clientsFolderId">Save Report In</label>
                  <div>
                    <Field
                      name="clientsFolderId"
                      render={({ field, form }) => (
                        <Input
                          style={{
                            width: '100%',
                            margin: '0 0 4px',
                          }}
                          {...field}
                          prefix={
                            <FolderOutlined
                              style={{ opacity: 0.4, marginRight: '4px' }}
                            />
                          }
                          suffix={
                            destinationFolder?.name ? (
                              <button
                                style={{ marginLeft: '5px' }}
                                onClick={() => {
                                  dispatch(setDestinationFolder(undefined));
                                }}
                              >
                                <CloseCircleFilled
                                  style={{ color: '#bababa' }}
                                />
                              </button>
                            ) : null
                          }
                          placeholder={'Select folder...'}
                          onChange={(e) => {
                            const { value } = e.target;
                            form.setFieldValue(field.name, value);
                          }}
                          value={destinationFolder?.name || ''}
                          readOnly
                          onClick={() => {
                            setIsFolderSelectModalActive(true);
                          }}
                        />
                      )}
                    />

                    <ErrorMessage
                      name="clientsFolderId"
                      component="div"
                      className="error"
                    />
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'flex-end',
                  }}
                >
                  {property?.apexReportUrl && (
                    <Button
                      style={{ height: '40px', marginRight: '8px' }}
                      onClick={() => {
                        handlePreviewClick();
                      }}
                    >
                      View Current Report
                    </Button>
                  )}
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ height: '40px' }}
                  >
                    Generate Report
                  </Button>
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default ApexReportForm;
