import { RootState } from '../../../store';

export const selectUser = (state: RootState) => state.auth.user;

export const selectClientIP = (state: RootState) => state.auth.clientIP;

export const selectFolderSearchSortTypes = (state: RootState) =>
  state.auth.values?.folderSearchSortTypes;

export const selectGoogleDriveRootFolderName = (state: RootState) =>
  state.auth.values?.googleDriveRootFolderName;
