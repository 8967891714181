import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosRequestConfig } from 'axios';
import { omit } from 'lodash';

import { endpoint } from '../../../foundation/config/axios_instances';
import {
  decryptData,
  encryptData,
  getSessionClientID,
} from '../../../foundation/utils/api';
import env_constants from '../../../internals/env/env_constants.json';

const getAPIOptions = (token: string, clientId: string) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-type': 'application/json',
      clientid: clientId,
    },
  };
};

export const fetchSuggestions: any = createAsyncThunk(
  'property_search/suggestions',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Search/suggestion`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.suggestions;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const fetchProperty: any = createAsyncThunk(
  'property_search/property',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(`${env_constants.PA_API_BASE_URL}/Search`, encryptedData, options)
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = decrypted.property;
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const fetchFolders: any = createAsyncThunk(
  'property_search/folders',
  async (
    apiParams: {
      token: string;
      data: any;
      trailIndex: number;
      folderName: string;
      isLoadNextBatch?: boolean;
    },
    thunkAPI: any,
  ) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(
      apiParams.isLoadNextBatch
        ? apiParams.data
        : omit(apiParams.data, ['nextPageToken']),
    );

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/Search/client-folders`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);
        const clientFolder = decrypted.clientFolder;

        const {
          nextPageToken,
          parentId,
          files: responseFolders,
        } = clientFolder;

        let folders = responseFolders;

        if (apiParams.isLoadNextBatch) {
          const currentFolders =
            thunkAPI.getState().property_search.clientFolders?.[
              apiParams.trailIndex
            ]?.folders;

          folders = (currentFolders ?? []).concat(responseFolders);
        }

        result = {
          trailIndex: apiParams.trailIndex,
          data: {
            folders: folders,
            nextPageToken: nextPageToken,
            folderId: parentId,
            folderName: apiParams.folderName,
            searchValue: apiParams.data.searchValue ?? undefined,
            orderAsc: apiParams.data.orderAsc,
            sortType: apiParams.data.sortType,
          },
        };
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);

export const generateApexReport: any = createAsyncThunk(
  'property_search/generateReport',
  async (apiParams: { token: string; data: any }, thunkAPI: any) => {
    let result;

    const clientId = getSessionClientID(
      thunkAPI.getState().auth.clientIP,
      apiParams.data.sessionId,
    );

    const options: AxiosRequestConfig = getAPIOptions(
      apiParams.token,
      clientId,
    );

    const encryptedData = encryptData(apiParams.data);

    await endpoint
      .post(
        `${env_constants.PA_API_BASE_URL}/ApexReport`,
        encryptedData,
        options,
      )
      .then((response) => {
        const decrypted = decryptData(response.data);
        const { cashAmount, equityAmount, equityInterestRate } = apiParams.data;

        result = {
          apexReportUrl: decrypted.apexReportUrl,
          formData: {
            cashAmount: `$${cashAmount}`,
            equityAmount: `$${equityAmount}`,
            equityInterestRate: `${(equityInterestRate * 100).toFixed(2)}%`,
          },
        };
      })
      .catch((error: any) => {
        const decryptedData = decryptData(error.response.data);

        return Promise.reject(new Error(decryptedData));
      });

    return result;
  },
);
