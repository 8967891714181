import {
  CloseCircleFilled,
  EnvironmentFilled,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  FormOutlined,
  HarmonyOSOutlined,
  LoadingOutlined,
  PictureOutlined,
  StarFilled,
} from '@ant-design/icons';
import { Button, Divider, Input, Tabs, TabsProps } from 'antd';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import reactStringReplace from 'react-string-replace';

import Search from '../../foundation/assets/svgs/Search';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import env_constants from '../../internals/env/env_constants.json';
import { selectUser } from '../authentication/redux/selectors';
import {
  selectProperty,
  selectPropertyId,
  selectSuggestions,
} from '../property_search/redux/selectors';
import ApexReportForm from './apex_report_form/ApexReportForm';
import { fetchProperty, fetchSuggestions } from './redux/async_thunks';
import { resetSuggestions, setPropertyId } from './redux/slice';

const PropertySearch = () => {
  const { PROPERTY_SEARCH_KEYWORD_MIN_LENGTH: KEYWORD_LENGTH } = env_constants;

  const dispatch = useDispatch();

  const inputRef: any = useRef(null);
  const submitRef: any = useRef(null);

  const user = useSelector(selectUser);
  const suggestions = useSelector(selectSuggestions);
  const property = useSelector(selectProperty);
  const propertyId = useSelector(selectPropertyId);

  const [searchTerm, setSearchTerm] = useState('');

  const [isSuggestionLoading, setIsSuggestionLoading] = useState(false);
  const [isLookupLoading, setIsLookupLoading] = useState(false);
  const [isImageError, setIsImageError] = useState(false);
  const [isSuggestionError, setIsSuggestionError] = useState(false);

  const getSuggestions = async (keyword: string) => {
    setIsSuggestionLoading(true);
    setIsSuggestionError(false);

    if (user) {
      try {
        const { token, userId, sessionId } = user;

        await dispatch(
          fetchSuggestions({
            token: token,
            data: {
              userId: userId,
              sessionId: sessionId,
              keyword: keyword,
            },
          }),
        ).unwrap();
      } catch (e) {
        setIsSuggestionError(true);
      }
    }

    setIsSuggestionLoading(false);
  };

  const getProperty = async () => {
    if (user && !isLookupLoading) {
      setIsLookupLoading(true);

      const { token, userId, sessionId } = user;

      await dispatch(
        fetchProperty({
          token: token,
          data: {
            userId: userId,
            sessionId: sessionId,
            propertyId: propertyId,
          },
        }),
      );

      setIsLookupLoading(false);
    }
  };

  const computeScoreColorValue = (number = 0) => {
    if (!number) {
      return '#ccc';
    }

    if (number < 1) {
      number = 1;
    } else if (number > 10) {
      number = 10;
    }

    const red = Math.round(255);
    let green: number;

    if (number >= 9) {
      green = Math.round(169 + 8 * 7);
    } else {
      green = Math.round(169 + 8 * (number - 1));
    }

    const blue = Math.round(64);

    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');

    return `#${redHex}${greenHex}${blueHex}`;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setSearchTerm(value);
    dispatch(setPropertyId(undefined));
    dispatch(resetSuggestions());

    if (value.length >= KEYWORD_LENGTH) {
      getSuggestions(value);
    }
  };

  const handleFocus = () => {
    if (propertyId) {
      const cleanAddress = searchTerm
        .replace(/(\b[A-Z]{2,3}\b|\b\d{4}\b)\s*/g, '')
        .replace(/,\s*$/, '')
        .trim();

      setSearchTerm(cleanAddress);
      dispatch(setPropertyId(undefined));
      getSuggestions(cleanAddress);
    }
  };

  const handleSuggestionClick = (suggestion: {
    propertyId: string;
    propertyAddress: string;
  }) => {
    setSearchTerm(suggestion.propertyAddress);
    dispatch(setPropertyId(suggestion.propertyId));

    dispatch(resetSuggestions());
  };

  const handleClear = (e: any) => {
    e.stopPropagation();

    setSearchTerm('');
    dispatch(setPropertyId(undefined));
    dispatch(resetSuggestions());
  };

  const handleImageError = () => {
    setIsImageError(true);
  };

  const renderLoader = (color?: string) => {
    return (
      <LoadingOutlined
        spin
        style={{ color: color ?? 'white', fontSize: '18px' }}
      />
    );
  };

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: (
        <span style={{ padding: '0 20px' }}>
          <FormOutlined style={{ marginRight: '5px' }} /> Inputs
        </span>
      ),
      children: <ApexReportForm />,
    },
    {
      key: '2',
      label: (
        <span style={{ padding: '0 20px' }}>
          <HarmonyOSOutlined style={{ marginRight: '7px' }} />
          Client Details
        </span>
      ),
      children: (
        <ul className="l-property-search__input-details">
          <li className="l-property-search__input-detail-item">
            <span>Purchasing Entity</span>
            <div className="l-property-search__badge h-margin--zero h-color--blue">
              {property?.clientDetails?.entityType ?? '-'}
            </div>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Focus</span>
            <div className="l-property-search__badge h-margin--zero h-color--blue">
              {property?.clientDetails?.topFocus ?? '-'}
            </div>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>LVR</span>
            <strong>{property?.clientDetails?.loanToValueRatio ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Load Product</span>
            <strong>{property?.clientDetails?.repayments ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Interest Rate</span>
            <strong>{property?.clientDetails?.interestRate ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span>Loan Pre-approval</span>
            <strong>{property?.clientDetails?.loanPreApproval ?? '-'}</strong>
          </li>
          <li className="l-property-search__input-detail-item">
            <span style={{ color: '#00b2a3' }}>Dashdot Fee</span>
            <strong>{property?.clientDetails?.totalFee ?? '-'}</strong>
          </li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    if (inputRef?.current && !searchTerm && !propertyId) {
      inputRef.current.focus();
    }

    if (submitRef?.current && propertyId) {
      submitRef.current.focus();
    }
  }, [searchTerm, propertyId]);

  useEffect(() => {
    // Workaround to clear suggestions when search input is emptied
    // before a successful fetch call
    if (searchTerm && searchTerm.length < KEYWORD_LENGTH) {
      dispatch(resetSuggestions());
    }
  }, [searchTerm, suggestions]);

  useEffect(() => {
    setIsImageError(false);
  }, [property]);

  return (
    <div className="l-property-search">
      <h3 className="l-property-search__heading">APEX Report Generator</h3>
      <div className="l-property-search__input-container">
        <Input
          className="l-property-search__input"
          ref={inputRef}
          placeholder="Search Address"
          onChange={handleChange}
          onFocus={handleFocus}
          value={searchTerm}
          prefix={
            <div className="l-property-search__input-prefix">
              {isSuggestionLoading ? (
                <>{renderLoader('#00b2a3')}</>
              ) : (
                <span style={{ opacity: '0.5', display: 'flex' }}>
                  <Search />
                </span>
              )}
            </div>
          }
          suffix={
            <Button
              onClick={handleClear}
              type="link"
              icon={
                searchTerm && (
                  <CloseCircleFilled style={{ color: '#bababa ' }} />
                )
              }
            />
          }
        />
        <Button
          className="l-property-search__lookup-btn"
          type="primary"
          ref={submitRef}
          onClick={() => {
            getProperty();
          }}
          disabled={!propertyId}
        >
          {isLookupLoading ? <>{renderLoader()}</> : <>Lookup Property</>}
        </Button>
        {searchTerm?.length >= KEYWORD_LENGTH &&
          !isSuggestionLoading &&
          isSuggestionError &&
          !suggestions?.length &&
          !propertyId && (
            <ul className="l-property-search__suggestions">
              <li className="l-property-search__suggestion-item l-property-search__suggestion-item--error">
                <ExclamationCircleOutlined
                  style={{
                    fontSize: '17px',
                    marginRight: '25px',
                    opacity: 0.5,
                  }}
                />
                No address found
              </li>
            </ul>
          )}
        {suggestions?.length > 0 && !propertyId && (
          <ul className="l-property-search__suggestions">
            {suggestions.map((suggestion: any) => (
              <li
                className="l-property-search__suggestion-item"
                key={suggestion.propertyId}
              >
                <button onClick={() => handleSuggestionClick(suggestion)}>
                  <EnvironmentOutlined
                    style={{
                      fontSize: '17px',
                      marginRight: '17px',
                      opacity: 0.5,
                    }}
                  />
                  <span>
                    {reactStringReplace(
                      suggestion.propertyAddress,
                      searchTerm,
                      (match: string | undefined) => {
                        return (
                          <strong key={suggestion.propertyId}>{match}</strong>
                        );
                      },
                    )}
                  </span>
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
      {!!property && (
        <section className="l-property-search__property">
          {isLookupLoading && <FullPageLoader noSpin />}
          <div className="l-property-search__property-info">
            <div className="l-property-search__property-photo">
              {property.photoUrl && !isImageError && (
                // NOSONAR
                <img
                  src={property.photoUrl}
                  alt={property.propertyAddress}
                  onError={handleImageError}
                />
              )}
              {(!property.photoUrl || isImageError) && (
                <span
                  style={{
                    color: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <PictureOutlined
                    style={{ marginRight: '8px', fontSize: '20px' }}
                  />
                  No photo available
                </span>
              )}
            </div>
            <div className="l-property-search__details">
              <div>
                <h3>AUD {property.listingPrice}</h3>
                <p>
                  <EnvironmentFilled
                    style={{
                      opacity: 0.7,
                      fontSize: '20px',
                      color: '#ff8920',
                    }}
                  />
                  {property.propertyAddress}
                </p>
                <div className="l-property-search__badge-container">
                  {property.propertyType && (
                    <div className="l-property-search__badge">
                      {property.propertyType}
                    </div>
                  )}
                  {property.state && (
                    <div className="l-property-search__badge">
                      {property.state}
                    </div>
                  )}
                  {property.propertyType && (
                    <div className="l-property-search__badge">
                      {property.landSize}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ display: 'flex' }}>
                <div className="l-property-search__score">
                  <span>
                    Lifestyle
                    <br />
                    Score
                  </span>
                  <div className="l-property-search__score-value">
                    <StarFilled
                      style={{
                        color: computeScoreColorValue(property.lifestyleScore),
                        fontSize: '56px',
                      }}
                    />
                    <span>
                      {property.lifestyleScore ? property.lifestyleScore : '-'}
                    </span>
                  </div>
                </div>
                <div className="l-property-search__score">
                  <span>
                    Convenience
                    <br />
                    Score
                  </span>
                  <div className="l-property-search__score-value">
                    <StarFilled
                      style={{
                        color: computeScoreColorValue(
                          property.convenienceScore,
                        ),
                        fontSize: '56px',
                      }}
                    />
                    <span>
                      {property.convenienceScore
                        ? property.convenienceScore
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Divider />
            <ul className="l-property-search__other-details">
              {property?.lgaDetails?.lgaName && (
                <li className="l-property-search__other-detail-item">
                  <div className="l-property-search__other-detail-label">
                    LGA
                  </div>
                  <div className="l-property-search__other-detail-value">
                    <strong>{property.lgaDetails.lgaName}</strong>
                  </div>
                </li>
              )}
              {property?.vacancy && (
                <li className="l-property-search__other-detail-item">
                  <div className="l-property-search__other-detail-label">
                    Suburb Vacancy Rate
                  </div>
                  <div className="l-property-search__other-detail-value">
                    <strong>{property.vacancy}</strong>
                  </div>
                </li>
              )}
              {property?.publicHousing && (
                <li className="l-property-search__other-detail-item">
                  <div className="l-property-search__other-detail-label">
                    Public Housing %
                  </div>
                  <div className="l-property-search__other-detail-value">
                    <strong>{property.publicHousing}</strong>
                  </div>
                </li>
              )}
              {property?.renters && (
                <li className="l-property-search__other-detail-item">
                  <div className="l-property-search__other-detail-label">
                    Renters %
                  </div>
                  <div className="l-property-search__other-detail-value">
                    <strong>{property.renters}</strong>
                  </div>
                </li>
              )}
              {property?.appraisedRent && (
                <li className="l-property-search__other-detail-item">
                  <div className="l-property-search__other-detail-label">
                    Rent Per Week
                  </div>
                  <div className="l-property-search__other-detail-value">
                    <strong>{property.appraisedRent}</strong>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="l-property-search__property-form">
            <h6
              style={{
                fontSize: '15px',
                fontWeight: 500,
                padding: '15px',
                marginBottom: '10px',
                display: 'flex',
                alignItems: 'center',
                backgroundColor: '#f7f8f9',
                borderRadius: '6px',
                justifyContent: 'center',
              }}
            >
              {property?.clientDetails?.clientName}
            </h6>
            <Tabs centered defaultActiveKey="1" items={items} />
          </div>
        </section>
      )}
    </div>
  );
};

export default PropertySearch;
